import * as React from "react";
import {useCallback, useRef} from "react";
import {animated} from "react-spring";
import * as _ from "lodash";
import {getContrastTonality} from "../../../../../../utils/colorPaletteUtils";
import color from "color";

function getContrastColor(colorInput) {
  return getContrastTonality(colorInput) === "light" ? "#FFFFFF" : '#212121'
}

export const CustomBarComponent = (props) => {
  // const {showTooltipFromEvent, showTooltipAt, hideTooltip} = useTooltip();
  const tooltipTimeout = useRef(null);
  const {
    bar,
    style,
    keys,
    selectedNodeData,
    updateSelectedNodeData,
    selectedTopic,
    selectedParentNodeData,
    updateSelectedParentNodeData,
    setTooltipData
  } = props;
  const adjustedHeight = Math.max(0, bar.height);
  const borderRadius = bar.width > 10 ? 10 : 0;
  const handleMouseEnter = useCallback((event) => {
    // setTooltipData({bar:bar.data,isSegmented: keys?.length > 1});
    if (tooltipTimeout.current) {
      clearTimeout(tooltipTimeout.current);
    }
    // tooltipTimeout.current = setTimeout(() => {
    //   setTooltipData({ bar: bar.data, isSegmented: keys?.length > 1 });
    // }, 2000);
    props.onMouseEnter?.(bar, event);
  }, [bar]);

  const handleMouseMove = useCallback((event) => {
    // setTooltipData({bar:bar.data,isSegmented: keys?.length > 1});
    if (tooltipTimeout.current) {
      clearTimeout(tooltipTimeout.current);
    }
    tooltipTimeout.current = setTimeout(() => {
      setTooltipData({ bar: bar.data, isSegmented: keys?.length > 1 });
    }, 1500);
    props.onMouseMove?.(bar, event);
  }, [bar]);


  const handleMouseLeave = useCallback((event) => {
    // hideTooltip();
    if (tooltipTimeout.current) {
      clearTimeout(tooltipTimeout.current);
      tooltipTimeout.current = null;
    }
    setTooltipData(null);
    props.onMouseLeave?.(bar, event);
  }, [bar]);

  const handleMouseClick = (event) => {
    console.log("Bar clicked: ",event);
    let newVar = _.cloneDeep(bar.data);
    newVar.data.color = bar.data.data.color || bar.data.data[`color_${bar.data.id}`];
    if (newVar.data.isParent) {
      updateSelectedParentNodeData(newVar);
    }
    if (selectedNodeData?.data?.isParent && selectedNodeData.id !== newVar.id) {
      updateSelectedParentNodeData(selectedNodeData)
    } else if (selectedParentNodeData && isSelected) {
      newVar = selectedParentNodeData;
      updateSelectedParentNodeData(newVar)
    } else if (isSelected) {
      newVar = null;
    }
    updateSelectedNodeData(newVar);
    props.onMouseClick?.(bar, event);
  }

  const isSelected = selectedNodeData ? bar.data.id === selectedNodeData.id && bar.data.indexValue === selectedNodeData.indexValue : false
  let barStyle = {
    opacity: 0.95,
    strokeWidth: 1,
    stroke: bar.data.data.color || bar.data.data[`color_${bar.data.id}`]
  };
  if (selectedNodeData && !selectedTopic?.isParent) {
    barStyle = {
      opacity: isSelected&&selectedParentNodeData?.indexValue!==selectedNodeData?.indexValue? 0.95 : 0.4,
      strokeWidth: isSelected ? 5 : 1,
      stroke: isSelected ? color(bar.data.data.color || bar.data.data[`color_${bar.data.id}`]).darken(0.2).hex() : bar.data.data.color || bar.data.data[`color_${bar.data.id}`]
    };
  }
  if(selectedParentNodeData?.indexValue===selectedNodeData?.indexValue){
    barStyle = {
      opacity: 0.95,
      strokeWidth:  1,
      stroke: isSelected ? color(bar.data.data.color || bar.data.data[`color_${bar.data.id}`]).darken(0.2).hex() : bar.data.data.color || bar.data.data[`color_${bar.data.id}`]
    };
  }

  return (
    <animated.g
      width={bar.width}
      height={bar.height}
      x={bar.x}
      y={bar.y}
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onMouseDown={handleMouseClick}
      // onClick={handleMouseClick}
      cursor='pointer'
    >
      <animated.path
        d={style.width.to((width) => `
        M${bar.x},${bar.y + adjustedHeight}
        L${bar.x},${bar.y}
        L${bar.x + width - borderRadius},${bar.y}
        Q${bar.x + width},${bar.y} ${bar.x + width},${bar.y + borderRadius}
        L${bar.x + width},${bar.y + adjustedHeight - borderRadius}
        Q${bar.x + width},${bar.y + adjustedHeight} ${bar.x + width - borderRadius},${bar.y + adjustedHeight}
        Z
      `)}
        fill={bar.data.data.color || bar.data.data[`color_${bar.data.id}`]}
        {...barStyle}
        onMouseDown={(event)=>{console.log("Path mouse down",event)}}
        onClick={(event)=>console.log("Path clicked",event)}
      >
      </animated.path>
      {props.shouldRenderLabel && (
        <text
          x={bar.x + (bar.width / 2)}
          y={bar.y + (bar.height / 2)}
          textAnchor={'middle'}
          dominantBaseline={'middle'}
          fontSize={'12px'}
          fontWeight={'500'}
          fontFamily={'Montserrat'}
          fontStyle={'normal'}
          fill={getContrastColor(bar.data.data.color || bar.data.data[`color_${bar.data.id}`])}
        >
          {props.label}
        </text>
      )}
    </animated.g>
  )
    ;
};
